<template>
  <main-container>
    <page-sizer>
      <v-toolbar>
        <v-toolbar-title>
          Honors
        </v-toolbar-title>
      </v-toolbar>

      <v-data-iterator
        :items="$store.state.honors"
        :items-per-page="5"
        :search="search"
      >
        <template #default="{items}">
          <v-card
            v-for="(honor, index) in items"
            :key="`honor-${index}`"
            class="my-2"
          >
            <v-card-text v-html="honor.text" class="text-left"></v-card-text>

            <v-toolbar
              flat
              style="font-size: 0.875rem"
              class="grey--text text--darken-1"
            >
              {{ honor.num }} Received
              <v-spacer></v-spacer>
              {{ honor.year }}
            </v-toolbar>
          </v-card>
        </template>
      </v-data-iterator>
    </page-sizer>
  </main-container>
</template>

<script>
import MainContainer from "../components/MainContainer.vue";
import PageSizer from "../components/PageSizer.vue";
export default {
  components: { MainContainer, PageSizer },
  data: () => ({
    search: "",
  }),
};
</script>
